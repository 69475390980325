var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "notification-grid"
  }, [_c('b-card', [_c('b-card-title', [_vm._v("Risk assessment")]), _c('euro-radio', {
    attrs: {
      "size": "lg",
      "options": _vm.radioOptions
    },
    model: {
      value: _vm.form.riskAssessment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "riskAssessment", $$v);
      },
      expression: "form.riskAssessment"
    }
  }), _c('b-btn', {
    attrs: {
      "variant": "primary",
      "block": ""
    }
  }, [_vm._v("Submit")])], 1), _c('b-card', [_c('b-card-title', [_vm._v("Secondment")]), _c('euro-radio', {
    attrs: {
      "size": "lg",
      "options": _vm.radioOptions
    },
    model: {
      value: _vm.form.secondment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "secondment", $$v);
      },
      expression: "form.secondment"
    }
  }), _c('b-btn', {
    attrs: {
      "variant": "primary",
      "block": ""
    }
  }, [_vm._v("Submit")])], 1), _c('b-card', [_c('b-card-title', [_vm._v("Physical person")]), _c('euro-radio', {
    attrs: {
      "size": "lg",
      "options": _vm.radioOptions
    },
    model: {
      value: _vm.form.physicalPerson,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "physicalPerson", $$v);
      },
      expression: "form.physicalPerson"
    }
  }), _c('b-btn', {
    attrs: {
      "variant": "primary",
      "block": ""
    }
  }, [_vm._v("Submit")])], 1), _c('b-card', [_c('b-card-title', [_vm._v("Legal person")]), _c('euro-radio', {
    attrs: {
      "size": "lg",
      "options": _vm.radioOptions
    },
    model: {
      value: _vm.form.legalPerson,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "legalPerson", $$v);
      },
      expression: "form.legalPerson"
    }
  }), _c('b-btn', {
    attrs: {
      "variant": "primary",
      "block": ""
    }
  }, [_vm._v("Submit")])], 1), _c('b-card', [_c('b-card-title', [_vm._v("Post")]), _c('euro-radio', {
    attrs: {
      "size": "lg",
      "options": _vm.radioOptions
    },
    model: {
      value: _vm.form.post,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "post", $$v);
      },
      expression: "form.post"
    }
  }), _c('b-btn', {
    attrs: {
      "variant": "primary",
      "block": ""
    }
  }, [_vm._v("Submit")])], 1), _c('b-card', [_c('b-card-title', [_vm._v("Employee")]), _c('euro-radio', {
    attrs: {
      "size": "lg",
      "options": _vm.radioOptions
    },
    model: {
      value: _vm.form.employee,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "employee", $$v);
      },
      expression: "form.employee"
    }
  }), _c('b-btn', {
    attrs: {
      "variant": "primary",
      "block": ""
    }
  }, [_vm._v("Submit")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="notification-grid">
    <b-card>
      <b-card-title>Risk assessment</b-card-title>
      <euro-radio v-model="form.riskAssessment" size="lg" :options="radioOptions"></euro-radio>
      <b-btn variant="primary" block>Submit</b-btn>
    </b-card>
    <b-card>
      <b-card-title>Secondment</b-card-title>
      <euro-radio v-model="form.secondment" size="lg" :options="radioOptions"></euro-radio>
      <b-btn variant="primary" block>Submit</b-btn>
    </b-card>
    <b-card>
      <b-card-title>Physical person</b-card-title>
      <euro-radio v-model="form.physicalPerson" size="lg" :options="radioOptions"></euro-radio>
      <b-btn variant="primary" block>Submit</b-btn>
    </b-card>
    <b-card>
      <b-card-title>Legal person</b-card-title>
      <euro-radio v-model="form.legalPerson" size="lg" :options="radioOptions"></euro-radio>
      <b-btn variant="primary" block>Submit</b-btn>
    </b-card>
    <b-card>
      <b-card-title>Post</b-card-title>
      <euro-radio v-model="form.post" size="lg" :options="radioOptions"></euro-radio>
      <b-btn variant="primary" block>Submit</b-btn>
    </b-card>
    <b-card>
      <b-card-title>Employee</b-card-title>
      <euro-radio v-model="form.employee" size="lg" :options="radioOptions"></euro-radio>
      <b-btn variant="primary" block>Submit</b-btn>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  data: () => ({
    radioOptions: ["Email", "Sms", "Internal"],
    form: {
      riskAssessment: "Email",
      secondment: "Email",
      physicalPerson: "Email",
      legalPerson: "Email",
      post: "Email",
      employee: "Email",
    },
  }),

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Administration") },
      { title: this.$t("Administration Notifications Dashboard") },
    ]);
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
}

.notification-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  gap: 2rem;
}
</style>
